import { Box } from "@chakra-ui/react";

/**
 * @typedef {object} Props
 * @property {boolean} isDisabled
 * @property {import("react").ReactNode} children
 */
/**
 * When disabled: dim the content and prevent pointer events
 * @param {Props} props
 */
export function Disable({ isDisabled, children }) {
  return (
    <Box
      opacity={isDisabled ? 0.5 : 1}
      pointerEvents={isDisabled ? "none" : undefined}
      transition="all .25s ease">
      {children}
    </Box>
  );
}
