import { useRouter } from "next/router";
import { useMemo } from "react";

/**
 * @param {any} value
 * @returns {number | undefined}
 */
export function getNumber(value) {
  if (typeof value === "string" && !isNaN(Number(value))) {
    return Number(value);
  }
  if (typeof value === "number") {
    return value;
  }
  return undefined;
}

/**
 * @param {string} key
 * @returns {number | undefined}
 */
export function useRouterNumber(key) {
  const router = useRouter();

  const value = useMemo(() => {
    return getNumber(router.query[key]);
  }, [key, router.query]);

  return value;
}
