import encodeQuery from "@splitfire-agency/raiden-library/dist/libraries/utils/encodeQuery";
import Router, { useRouter } from "next/router";
import { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import useSWRInfinite from "swr/infinite";
import useApiFetcher from "../../hooks/useApiFetcher";

/**
 * @template {{}} [T=any]
 * @typedef {object} Props
 * @property {T} defaultValues
 * @property {T} initialFields
 * @property {(fields: T, key: number, previousPage: any) => string | null} generateUrl
 * @property {boolean} useRouterUrl
 * @property {boolean} autoSubmit
 * @property {boolean} keepPreviousData
 * @property {any[]} [fallbackData]
 */

/**
 * Un hook permettant d'initialiser la valeur du contexte SearchInfiniteContext
 * @template {{}} T
 * @param {Props<T>} props
 * @returns {import("./SearchInfiniteProvider").SearchInfiniteContextValue<T>}
 */
export function useSearchInfinite({
  defaultValues,
  initialFields,
  generateUrl,
  useRouterUrl,
  autoSubmit,
  keepPreviousData,
  fallbackData,
}) {
  const apiFetcher = useApiFetcher();

  const [submittedFields, setSubmittedFields] = useState(
    /** @type {T} */ (initialFields),
  );

  const { asPath } = useRouter();

  /** @type {import("react-hook-form").UseFormReturn<T>} */
  const form = useForm({
    values: useRouterUrl ? initialFields : undefined, // updates the form values when the router values change
    // @ts-ignore
    defaultValues,
  });
  const { setValue, handleSubmit } = form;

  const getKey = useCallback(
    (key, previousPage) => {
      return generateUrl(submittedFields, key, previousPage);
    },
    [generateUrl, submittedFields],
  );

  const swrInfiniteResponse = useSWRInfinite(getKey, apiFetcher, {
    dedupingInterval: 0,
    keepPreviousData,
    // force refreshing the data on mount
    revalidateOnMount: true,
    revalidateFirstPage: false,
    fallbackData,
  });
  const { mutate, setSize } = swrInfiniteResponse;

  const search = useCallback(
    (values) => {
      if (useRouterUrl) {
        const modifiedValues = Object.keys(values).reduce((acc, key) => {
          if (Array.isArray(values[key])) {
            const isDifferent = values[key].some(
              (value) => !defaultValues[key].includes(value),
            );
            if (isDifferent) {
              acc[key] = values[key];
            }
          } else if (values[key] !== defaultValues[key]) {
            acc[key] = values[key];
          }
          return acc;
        }, {});

        Router.replace(
          `${asPath.split("?")[0]}${encodeQuery(modifiedValues, {
            appendPrefix: true,
          })}`,
          undefined,
          {
            shallow: true,
          },
        );
      }
      setSubmittedFields(values);
    },
    [useRouterUrl, asPath, defaultValues],
  );

  const submit = useMemo(() => {
    return handleSubmit((values) => {
      search(values);
    });
  }, [handleSubmit, search]);

  const appliedFilters = useMemo(() => {
    // for each key in submitted fields
    return Object.keys(submittedFields).reduce((acc, key) => {
      // if the submitted value is different from the default value
      if (Array.isArray(submittedFields[key])) {
        const isDifferent = submittedFields[key].some(
          (value) => !defaultValues[key].includes(value),
        );
        if (isDifferent) {
          acc = [...acc, key];
        }
      } else if (submittedFields[key] !== defaultValues[key]) {
        acc = [...acc, key];
      }
      return acc;
    }, /** @type {string[]} */ ([]));
  }, [defaultValues, submittedFields]);

  const reset = useCallback(() => {
    Object.keys(defaultValues).forEach((key) => {
      // @ts-ignore
      setValue(key, defaultValues[key], { shouldDirty: true });
    });
    if (autoSubmit === false) {
      search(defaultValues);
    }
  }, [defaultValues, autoSubmit, setValue, search]);

  const searchInfiniteContextValue = useMemo(() => {
    /** @type {import("./SearchInfiniteProvider").SearchInfiniteContextValue<T>} */
    const value = {
      swrInfiniteResponse,
      reset,
      appliedFilters,
      submit,
      form,
      autoSubmit,
      submittedFields,
      mutate,
      setSize,
      // @ts-expect-error
      handleSubmit,
    };
    return value;
  }, [
    appliedFilters,
    autoSubmit,
    form,
    mutate,
    reset,
    setSize,
    submit,
    submittedFields,
    swrInfiniteResponse,
    handleSubmit,
  ]);

  return searchInfiniteContextValue;
}
